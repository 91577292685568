<template>
  <iq-card>
    <template v-slot:headerTitle>
      <h4 class="card-title">
        {{ $t("profile.personalInformation") }}
      </h4>
    </template>
    <template v-slot:body>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-row align-h="between">
            <b-col md="2">
              <b-form-group
                class="row justify-content-center align-items-center"
              >
                <div class="profile-img-edit">
                  <b-img
                    :src="
                      preview
                        ? preview
                        : require('../../../assets/images/user/user-11.png')
                    "
                    class="profile-pic height-150 width-150"
                    alt="profile-pic"
                  />
                  <div class="p-image">
                    <div
                      class="position-relative cursos-pointer"
                      id="pick-image"
                    >
                      <i class="ri-pencil-line upload-button"></i>
                    </div>
                    <avatar-cropper
                      :upload-handler="selectedFile"
                      @changed="setFileName"
                      trigger="#pick-image"
                      :labels="{
                        submit: $t('app.accept'),
                        cancel: $t('app.cancel'),
                      }"
                      :cropper-options="{
                        aspectRatio: 1,
                        autoCropArea: 1,
                        viewMode: 1,
                        movable: false,
                        zoomable: false,
                      }"
                    />
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="10">
              <b-row align-v="center">
                <b-form-group
                  class="col-md-6"
                  :label="$t('profile.rut')"
                  label-for="rut"
                >
                  <ValidationProvider
                    :name="$t('profile.rut')"
                    rules="required|min:9"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="user.rut"
                      type="text"
                      readonly
                      :placeholder="$t('profile.rut')"
                      v-mask="['##.###.###-X', '#.###.###-X']"
                    ></b-form-input>
                    <div v-if="errors[0]">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  :label="$t('profile.name')"
                  label-for="name"
                >
                  <ValidationProvider
                    :name="$t('profile.name')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="user.name"
                      type="text"
                      :placeholder="$t('profile.name')"
                    ></b-form-input>
                    <div v-if="errors[0]">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  :label="$t('profile.first_lastname')"
                  label-for="fname"
                >
                  <ValidationProvider
                    :name="$t('profile.first_lastname')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="user.first_lastname"
                      type="text"
                      :placeholder="$t('profile.first_lastname')"
                    ></b-form-input>
                    <div v-if="errors[0]">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  :label="$t('profile.second_lastname')"
                  label-for="lname"
                >
                  <ValidationProvider
                    :name="$t('profile.second_lastname')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="user.second_lastname"
                      type="text"
                      :placeholder="$t('profile.second_lastname')"
                    ></b-form-input>
                    <div v-if="errors[0]">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  :label="$t('profile.email')"
                  label-for="email"
                >
                  <ValidationProvider
                    vid="email"
                    :name="$t('profile.email')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      class="form-control mb-0"
                      id="email"
                      name="email"
                      :placeholder="$t('profile.email')"
                      v-model="user.email"
                    />
                    <div v-if="errors[0]">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  :label="$t('profile.phone')"
                  label-for="phone"
                >
                  <ValidationProvider
                    vid="phone"
                    :name="$t('profile.phone')"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <vue-tel-input
                      :dropdownOptions="{
                        showFlags: true,
                        width: '200px',
                        tabindex: 1,
                        showDialCodeInSelection: false,
                      }"
                      mode="international"
                      :inputOptions="{
                        placeholder: $t('colleges.phone'),
                        maxlength: 15,
                        showDialCode: false,
                      }"
                      :defaultCountry="56"
                      v-model="user.phone"
                    ></vue-tel-input>
                    <div v-if="errors[0]">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  :label="$t('profile.birthdate')"
                  label-for="birthdate"
                >
                  <date-picker
                    v-model="user.birthdate"
                    :lang="'es'"
                    type="datet"
                    format="DD-MM-YYYY"
                    value-type="YYYY-MM-DD"
                    :input-class="`form-control`"
                    style="width: 100% !important"
                    :placeholder="$t('profile.birthdate')"
                  ></date-picker>
                </b-form-group>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-end">
            <b-button
              variant="outline-danger"
              @click="$router.back()"
              class="float-right mr-1"
            >
              {{ $t("app.cancel") }}
            </b-button>
            <b-button
              type="submit"
              :disabled="isLoading"
              variant="success"
              class="mr-2"
            >
              <span v-if="isLoading">
                <b-spinner small type="grow"></b-spinner>
                {{ $t("profile.loading") }}...
              </span>
              <span v-else> {{ $t("app.save") }} </span>
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </iq-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { core } from "@/config/pluginInit";
import AvatarCropper from "vue-avatar-cropper";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  components: {
    AvatarCropper,
    DatePicker,
  },
  mounted() {
    if (this.currentUser?.id) {
      let {
        id,
        rut,
        name,
        first_lastname,
        second_lastname,
        email,
        phone,
        locale,
        avatar,
        birthdate,
      } = this.currentUser;
      this.preview = avatar;
      this.user = {
        id,
        rut,
        name,
        first_lastname,
        second_lastname,
        email,
        phone,
        locale,
        birthdate: moment(birthdate).format("YYYY-MM-DD"),
      };
    }
  },
  data() {
    return {
      fileSelected: null,
      preview: "",
      user: {
        id: "",
        rut: "",
        name: "",
        first_lastname: "",
        second_lastname: "",
        email: "",
        birthdate: "",
        phone: "",
        locale: "",
        avatar: "",
      },
    };
  },
  methods: {
    ...mapActions({
      updateProfile: "updateProfile",
    }),
    //Covert any dataURL to file Object JS

    setFileName(file) {
      this.fileSelected = file;
      this.user.avatar = file;
    },
    async selectedFile(cropper) {
      const dataUrl = cropper
        .getCroppedCanvas()
        .toDataURL(this.fileSelected.type);
      const file = core.dataURLtoFile(dataUrl, this.fileSelected.name);
      this.preview = dataUrl;
      this.user.avatar = file;
    },
    async onSubmit() {
      const resp = await this.updateProfile(this.user);
      if (resp?.status == 200) {
        core.showSnackbar("success", resp.data.message);
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "user",
      isLoading: "isLoading",
    }),
  },
};
</script>
<style>
.vue-tel-input {
  border-radius: 7px !important;
  border: 1px solid rgb(211, 211, 211);
  padding: 3px;
}
</style>
