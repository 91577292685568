<template>
  <iq-card class="shadow">
    <template v-slot:headerTitle>
      <h4 class="card-title">
        {{ $t("profile.changePassword") }}
      </h4>
    </template>
    <template v-slot:body>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(savePassword)">
          <b-row>
            <b-form-group class="col-md-4">
              <label for="cpass">{{ $t("profile.currentPassword") }}:</label>

              <ValidationProvider
                :name="$t('profile.currentPassword')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="user.current_password"
                  type="password"
                  :placeholder="$t('profile.currentPassword')"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                ></b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
            <b-form-group
              class="col-md-4"
              :label="`${$t('profile.newPassword')}:`"
              label-for="pass"
            >
              <ValidationProvider
                :name="$t('profile.password')"
                rules="required|confirmed:repeat_password"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="user.new_password"
                  type="password"
                  :placeholder="$t('profile.newPassword')"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                ></b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
            <b-form-group
              class="col-md-4"
              :label="`${$t('profile.repeatPassword')}:`"
              label-for="rpass"
            >
              <ValidationProvider
                vid="repeat_password"
                :name="$t('profile.repeatPassword')"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-input
                  v-model="user.new_password_confirmation"
                  type="password"
                  :placeholder="$t('profile.newPassword')"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                ></b-form-input>
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-row>
          <div class="d-flex justify-content-end">
             <b-button
              variant="outline-danger"
              type="cancel"
              @click="$router.back()"
              class="float-right mr-1"
            >
              {{ $t("app.cancel") }}
            </b-button>
            <b-button
              type="submit"
              :disabled="isLoading"
              variant="success"
              class="mr-2"
            >
              <span v-if="isLoading">
                <b-spinner small type="grow"></b-spinner>
                {{ $t("profile.loading") }}...
              </span>
              <span v-else> {{ $t("app.save") }} </span>
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </iq-card>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      user: {
        current_password: "",
        new_password: "",
        new_password_confirmation: "",
      },
    };
  },
  methods: {
    ...mapActions({
      updatePassword: "updatePassword",
    }),
    async savePassword() {
      const resp = await this.updatePassword(this.user);
      console.log(resp);
      if (resp?.status == 200) {
        core.showSnackbar("success", resp.data.message);
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
    }),
  },
};
</script>