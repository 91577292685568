<template>
  <iq-card>
    <template v-slot:headerTitle>
      <h4 class="card-title">Configuración</h4>
    </template>
    <template v-slot:body>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="form-group row align-items-center">
            <label class="col-md-12" for="radio-group-2">Lenguaje:</label>
            <div class="col-md-12">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="radio-group-2"
                  v-model="lang"
                  :aria-describedby="ariaDescribedby"
                  name="radio-sub-component"
                >
                  <b-form-radio value="es"
                    ><span><b-img :src="flagEs" class="mr-2" /></span>
                    Español
                  </b-form-radio>
                  <b-form-radio value="en"
                    ><span><b-img :src="flagEn" class="mr-2" /></span
                    >Inglés</b-form-radio
                  >
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <b-button type="submit" :disabled="isLoading" variant="primary" class="mr-2"
              ><span v-if="isLoading">
                <b-spinner small type="grow"></b-spinner>
                {{ $t("profile.loading") }}...
              </span>
              <span v-else> {{ $t("profile.save") }} </span></b-button
            >
          </div>
        </form>
      </ValidationObserver>
    </template>
  </iq-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { core } from "@/config/pluginInit";

export default {
  mounted() {
      this.lang = this.currentLang.locale
  },
  data() {
    return {
      lang: "",
      flagEn: require("@/assets/images/small/flag-01.png"),
      flagEs: require("@/assets/images/small/es.png"),
    };
  },
  methods: {
    ...mapActions({
      updateLang: "updateLang",
    }),
    async onSubmit() {
      const resp = await this.updateLang(this.lang);
      if (resp?.status == 201) {
        core.showSnackbar("success", resp.data.message);
      }
    },
  },
  computed: {
    ...mapGetters({
      currentLang: "user",
      isLoading: "isLoading",
    }),
  },
};
</script>