<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <div class="iq-edit-list-data">
          <PersonalInformation />
          <ChangePassword />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import PersonalInformation from "./Components/UserWizard.vue";
import ChangePassword from "./Components/PasswordWizard.vue";
import ChangeLang from "./Components/LangWizard.vue";
export default {
  name: "ProfileEdit",
  components: {
    PersonalInformation,
    ChangePassword,
    ChangeLang,
  },
};
</script>
